<template>
  <div class="excelDownloadButton">
    <download-excel
      :data="json_data"
      :fields="json_fields"
      :name="'tpo_' + formatDate(new Date()) + '.xls'"
      >Download&nbsp;XLS</download-excel
    >
  </div>
</template>

<script>
import { formatDate } from '../../globalFunctions/date'

export default {
  props: ['items'],
  created() {},
  computed: {
    json_data() {
      if (this.items) {
        let temp = this.items.map((item) => {
          item.tempUnique = item.productId + '_' + item.unit
          return item
        })

        temp = JSON.parse(JSON.stringify(this.items)).reduce((base, obj) => {
          let index = base.findIndex((el) => el.tempUnique === obj.tempUnique)
          if (index == -1) {
            base.push(obj)
          } else {
            base[index].totalAmount += obj.totalAmount
          }
          return base
        }, [])

        return temp.map((item) => {
          return {
            product: item.productName,
            amount: item.totalAmount + ' ' + item.unit,
          }
        })
      }
    },
  },
  methods: {
    formatDate
  },
  data() {
    return {
      json_fields: {
        Product: 'product',
        Amount: 'amount',
      },
    }
  },
}
</script>

<style lang="scss">
.excelDownloadButton {
  display: inline-block;
  height: 100%;
  padding: 2px 15px;
  padding-top: 6px;
  background: $accent;
  border-radius: 6px;
  border: none;
  color: white;
  font-family: 'Spartan' !important;
  font-weight: 550;
  font-size: 9pt;
  -webkit-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  -moz-box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  cursor: pointer;
  height: 30px;
  letter-spacing: 0.5px;
  margin-left: 10px;
}

::v-deep .excelDownloadButton div {

  font-family: 'Spartan' !important;

}

@media screen and (max-width: 700px) {
  .excelDownloadButton {
    margin-left: 0px;
  }
}
</style>