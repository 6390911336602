<template>
  <fragment>
    <tr class="tableRow" :key="item.uniqueIdenitifier" v-for="item in groupProducts">
      <td>{{ item.productName }}</td>
      <td>{{ Number(item.totalAmount.toFixed(5))+0 + ' ' + item.unit }}</td>
      <td style="white-space:nowrap">{{ item.amountOrders }} orders</td>
    </tr>
  </fragment>
</template>

<script>
export default {
  props: ['items', 'week', 'day'],
  computed: {
    filteredItems() {
      if (this.week) {
        return this.items.filter((item) => {
          if (new Date(item.datePickupDelivery).getWeek() == this.week) {
            return item
          }
        })
      } else if (this.day) {
        return this.items.filter((item) => {
          if (item.datePickupDelivery == this.day) {
            return item
          }
        })
      }
    },
    groupProducts() {
      return JSON.parse(JSON.stringify(this.filteredItems)).reduce(
        (base, obj) => {
          let index = base.findIndex((el) => el.dateProductUnique === obj.dateProductUnique)
          if (index == -1) {
            base.push(obj)
          } else {
            base[index].totalAmount += obj.totalAmount
            base[index].amountOrders += obj.amountOrders
          }
          return base
        },
        []
      )
    },
  },
}
</script>

<style scoped lang="scss">
@import url('./../../assets/css/table.css');


</style>