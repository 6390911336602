<template>
  <div>
    <div class="pageWrapper">
      <div class="header">
        <div class="topbar">
          <div class="titleBar">
            <H2 v-if="isMobile || !rangeDate">
              Total products ordered
            </H2>
            <H2 v-else>
              Total products ordered from {{ rangeDate[0] }} to
              {{ rangeDate[1] }}
            </H2>
          </div>
          <Excel :items="items" />
        </div>
        <br v-if="isMobile" />
        <SearchInput placeholder="Search" v-model="searchString" />
        <div class="filterBar" style="margin-top: 5px">
          <select v-model="sortMethod" class="dropDownSelect">
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
          </select>
          <select v-model="deliveryMethod" class="dropDownSelect">
            <option :value="null">All</option>
            <option value="hemleverans">Home delivery</option>
            <option value="drive_through">Drive through</option>
            <option value="in_store">In shop</option>
          </select>
          <ShopPicker />
          <DatePicker
            v-model="rangeDate"
            value-type="MM-DD-YYYY"
            :range="true"
            :default-value="Date.now()"
            :range-separator="' to '"
            @input="getItemsWithDate"
            @clear="clearDateRange"
            placeholder="Specify the date"
          ></DatePicker>
          <!-- <select
            v-if="deliveryMethod != 'hemleverans'"
            v-model="group"
            class="dropDownSelect"
          >
            <option :value="null">All</option>
            <option :key="group.id" :value="group.group_slug" v-for="group in groups">
              {{ group.group_name }}
            </option>
          </select> -->
        </div>
      </div>

      <div style="top: 50px; position: relative">
        <table class="standardTable">
          <tr class="standardTableHeader">
            <th>Product</th>
            <th>Amount</th>
            <th>Orders</th>
          </tr>
          <LoadingRowTable :dataList="items" :columnsAmount="3">
            <fragment v-if="sortMethod == 'daily'">
              <fragment :key="day" v-for="day in days">
                <tr class="dividerRow">
                  {{
                    day
                  }}
                </tr>
                <TotalItemWrapper :day="day" :items="items" />
              </fragment>
            </fragment>
            <fragment v-if="sortMethod == 'weekly'">
              <tr></tr>
              <fragment :key="week" v-for="week in weeks">
                <tr class="dividerRow">
                  Week
                  {{
                    week
                  }}
                </tr>
                <TotalItemWrapper :week="week" :items="items" />
              </fragment>
            </fragment>
          </LoadingRowTable>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import LoadingRowTable from '@/components/LoadingRowTable.vue'
import TotalItemWrapper from '@/components/total/TotalItemWrapper'
import Excel from '@/components/total/Excel'
import ShopPicker from '@/components/ShopPicker'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  created() {
    this.getItems()
  },
  data() {
    return {
      baseItems: [],
      items: this.baseItems,
      searchString: '',
      weeks: [],
      days: [],
      sortMethod: 'weekly',
      rangeDate: null,
      deliveryMethod: null,
    }
  },
  components: {
    LoadingRowTable,
    TotalItemWrapper,
    DatePicker,
    Excel,
    ShopPicker,
    SearchInput,
    H2,
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
    shops: {
      get() {
        return this.$store.state.selectedShops
      },
    },
  },
  watch: {
    shops() {
      this.getItems()
    },
    deliveryMethod() {
      this.getItems()
    },
    searchString() {
      this.getItems()
    },
  },
  methods: {
    getItems() {
      let tempRangeDate = null
      if (this.rangeDate) {
        tempRangeDate = this.rangeDate.map((date) => {
          return new Date(date)
        })
      }
      axios
        .post('total/getTotal', {
          date: tempRangeDate,
          shops: this.shops,
          deliveryMethod: this.deliveryMethod,
          searchString: this.searchString,
        })
        .then((res) => {
          this.baseItems = res.data
          this.items = this.baseItems
          this.calcWeeks()
          this.calcDays()
        })
    },
    getItemsWithDate() {
      this.getItems()
    },
    clearDateRange() {
      this.rangeDate = null
      this.getItems()
    },
    compareDate(a, b) {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    },
    calcWeeks() {
      this.weeks = []
      this.items.forEach((item) => {
        let week = new Date(item.datePickupDelivery).getWeek()
        if (!this.weeks.includes(week)) {
          this.weeks.push(week)
        }
      })

      this.weeks.sort(this.compareDate)
    },
    calcDays() {
      this.days = []
      this.items.forEach((item) => {
        if (!this.days.includes(item.datePickupDelivery)) {
          this.days.push(item.datePickupDelivery)
        }
      })
      this.days.sort(this.compareDate)
    },
  },
}
</script>

<style scoped lang="scss">
@import url('../../assets/css/table.css');

.header {
  background: #f1f1f1;
  z-index: 0;
  width: 100%;
  -webkit-box-shadow: 0px 10px 20px 14px rgba(241, 241, 241, 1);
  -moz-box-shadow: 0px 10px 20px 14px rgba(241, 241, 241, 1);
  box-shadow: 0px 10px 20px 14px rgba(241, 241, 241, 1);
}

::v-deep .mx-datepicker-range {
  cursor: pointer;
  width: 210px;
}

::v-deep .mx-input {
  height: 27px !important;
  top: 0px;
  position: relative;
  cursor: pointer;
  font-size: 10pt;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(219, 219, 219, 1);
  border: none;
}

.filterButton {
  display: inline-block;
  padding: 5px 10px;
  margin-left: 10px;
  background: #2694de;
  border-radius: 3px;
  border: none;
  height: 28px;
  color: white;
  font-family: 'Spartan';
  font-size: 9pt;
  box-shadow: 0px 0px 3px 0px rgb(175, 175, 175);
  cursor: pointer;
  top: 2px;
  position: relative;
}

@media screen and (min-width: 700px) {
  .titleBar {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .filterBar > * {
    display: block;
    margin: 5px 0;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch:hover ~ .weightToolTip {
  opacity: 0.9;
}

.weightToolTip {
  position: absolute;
  background: #555;
  z-index: 10;
  color: white;
  padding: 3px 7px;
  opacity: 0;
  border-radius: 5px;
  transition: 0.3s;
  font-size: 10pt;
}
</style>